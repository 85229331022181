var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bg"},[_c('div',[_c('div',{staticClass:"select-date-label"},[_vm._v("選擇日期範圍")]),_c('div',{staticClass:"date-container"},[_c('div',{staticClass:"form-check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedOption),expression:"selectedOption"}],staticClass:"form-check-input",attrs:{"type":"radio","id":"all-records","value":"all"},domProps:{"checked":_vm._q(_vm.selectedOption,"all")},on:{"change":function($event){_vm.selectedOption="all"}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"all-records"}},[_vm._v("全部紀錄")])]),_c('div',{staticClass:"form-check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedOption),expression:"selectedOption"}],staticClass:"form-check-input",attrs:{"type":"radio","id":"custom-range","value":"custom"},domProps:{"checked":_vm._q(_vm.selectedOption,"custom")},on:{"change":function($event){_vm.selectedOption="custom"}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"custom-range"}},[_vm._v("自訂日期範圍")])]),_c('div',{staticClass:"bg-date"},[_c('b-form-group',{class:{ 'custom-label': true, 'error-label': _vm.showStateError },attrs:{"label":"開始日期"}},[_c('b-form-datepicker',_vm._b({class:{ 'download-error': _vm.showStateError },attrs:{"disabled":_vm.selectedOption !== 'custom',"placeholder":_vm.showStateError ? '請選擇日期' : '年/月/日',"date-format-options":{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            },"locale":'zh'},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'b-form-datepicker',_vm.labels['zh'],false))],1),_c('b-form-group',{class:{ 'custom-label': true, 'error-label': _vm.showStateError },attrs:{"label":"結束日期"}},[_c('b-form-datepicker',_vm._b({class:{ 'download-error': _vm.showStateError },attrs:{"disabled":_vm.selectedOption !== 'custom',"placeholder":_vm.showStateError ? '請選擇日期' : '年/月/日',"date-format-options":{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            },"locale":'zh'},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'b-form-datepicker',_vm.labels['zh'],false))],1)],1)])]),_c('div',{staticClass:"select-city-label"},[_vm._v("選擇縣市")]),_c('div',{staticClass:"date-container"},[_c('b-form-select',{staticClass:"w-100 custom-select",model:{value:(_vm.citySelect),callback:function ($$v) {_vm.citySelect=$$v},expression:"citySelect"}},_vm._l((_vm.cities),function(city,index){return _c('option',{key:index},[_vm._v(" "+_vm._s(city)+" ")])}),0)],1),_c('a',{staticClass:"download-btn btnBlue w-100",on:{"click":function($event){return _vm.downloadOrder()}}},[_c('img',{attrs:{"src":require("../assets/icon/Download.svg"),"alt":""}}),_c('span',[_vm._v("下載訂單紀錄")]),(_vm.isLoading)?_c('span',[_vm._v("中...")]):_vm._e(),(_vm.isLoading)?_c('Loading'):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }