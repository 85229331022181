
import { Component, Vue } from "vue-property-decorator";
import * as DI from "@/utility/DependencyInjector";

@Component({
  components: {},
})
export default class MobileDownloadOrder extends Vue {
  labels = {
    zh: {
      weekdayHeaderFormat: "narrow",
      labelPrevDecade: "過去十年",
      labelPrevYear: "上一年",
      labelPrevMonth: "上個月",
      labelCurrentMonth: "當前月份",
      labelNextMonth: "下個月",
      labelNextYear: "明年",
      labelNextDecade: "下一個十年",
      labelToday: "今天",
      labelSelected: "選定日期",
      labelNoDateSelected: "未選擇日期",
      labelCalendar: "日曆",
      labelNav: "日曆導航",
      labelHelp: "使用光標鍵瀏覽日期",
    },
  };
  //data
  citySelect: string = "所有縣市";
  cities = [
    "所有縣市",
    "台北市",
    "新北市",
    "基隆市",
    "桃園市",
    "新竹縣",
    "新竹市",
    "苗栗縣",
    "台中市",
    "南投縣",
    "彰化縣",
    "雲林縣",
    "嘉義縣",
    "嘉義市",
    "台南市",
    "高雄市",
    "屏東市",
    "宜蘭縣",
    "花蓮縣",
    "台東縣",
    "澎湖縣",
    "金門縣",
    "連江縣",
  ];
  isLoading = false;
  selectedOption = "all";
  startDate = null;
  endDate = null;
  showStateError = false;
  id = "";

  //hook
  mounted(): void {
    // 從 URL 中獲取 orderId 並賦值給 id
    const urlParams = new URLSearchParams(window.location.search);
    const orderId = urlParams.get("orderId");
    if (orderId) {
      this.id = orderId;
    }
  }

  //methods
  downloadOrder(): void {
    if (this.selectedOption === "custom") {
      if (this.startDate === null || this.endDate === null) {
        this.showStateError = true;
      } else {
        this.showStateError = false;
      }
    }
    if (this.selectedOption === "all") {
      this.startDate = null;
      this.endDate = null;
    }
    const data = {
      userId: this.id,
      city: this.citySelect === "所有縣市" ? "" : this.citySelect,
      startDate: this.startDate === null ? "" : this.startDate,
      endDate: this.endDate === null ? "" : this.endDate,
    };
    
    if (!this.showStateError) {
      const res = DI.get("App").repairOrder.exportReport(data);
      res
        .then((d: Blob) => {
          const csvUrl = URL.createObjectURL(d);
          let link = document.createElement("a");
          link.setAttribute("href", csvUrl);
          let fileName = `exportReport.xlsx`;
          link.setAttribute("download", fileName);
          link.click();
        })
        .then(() => {
          this.isLoading = false;
        });
    }
  }
}
